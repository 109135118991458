@import "globals.scss";
.footer_desktop {
  max-width: 1440px;
  padding: 50px 0;
  display: grid;
  row-gap: 60px;
  &_content {
    display: flex;
    justify-content: space-between;
    padding: 0 110px;
    .head {
      color: $teal-4;
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
      padding-bottom: 6px;
    }
    .sub {
      color: $white;
      font-size: 18px;
      line-height: 21px;
      padding-top: 17px;
      &_boldtext {
        font-weight: 500;
      }
      a {
        text-decoration: none;
        color: $white;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }

  &_logos {
    &_media {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      place-items: center;
      column-gap: 10px;
      .youtube {
        // padding-left: 10px;
      }
    }
    &_media2 {
      display: flex;
    }
  }
}

//at 1440 px
@include dynamicQuery(1440px) {
  .footer_desktop {
    &_content {
      padding: 0 60px;
    }
  }
}

//at 744px
@include dynamicQuery(1000px) {
  .footer_desktop {
    &_content {
      padding: 0 30px;
      .head {
        font-size: 20px;
        line-height: 23px;
        padding-bottom: 6px;
      }
      .sub {
        font-size: 16px;
        line-height: 18px;
        padding-top: 17px;
      }
    }
  }
}
