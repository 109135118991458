@import "globals.scss";
.get_help_card {
  display: flex;
  justify-content: center;
  background: $white;
  border-radius: 3rem;
  .get_help_card_body {
    margin: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .get_help_card_image_mobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .get_help_card_text1 {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-weight: 700;
      font-size: 14px;
      line-height: 3.1rem;
      display: flex;
      justify-content: center;
    }
    .get_help_card_text2 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.8em;
      display: flex;
      justify-content: center;
      color: $black;
      opacity: 0.6;
    }
  }
}
