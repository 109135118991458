$font-primary: 'Roboto';
$font-secondary: 'DM sans Bold';
$font-tertiary: 'Lucida Grande';

$brown-1: #ffeeee;
$brown-2: #aa1d1d;

$blue-1: #bcccdc; //tabs content border
$blue-2: #d6ebf9; //vi iconbg
$blue-3: #e6f8ff; //pfyt iconbg
$blue-4: #5cbdc5; //username bg
$blue-5: #3ac7ff; //pfyt icon
$blue-5: #389bdc; //vi icon
$blue-6: #3498db; //username text,links,active menu text,tabs active
$blue-7: #3398db; //buttons

$blue-8: #25a4e7;
$blue-9: #20b2e3;

$dark-blue-1: #4f7c9d; //stepcounter //sec-title
$dark-blue-2: #28456e; //stepcounter text

$off-white: #fafafa; //whitetext,icons

$white: #ffffff; //menubar bg

$gray-1: #f9fafe; //highlighted bg, sidemenu bg
$gray-2: #e6e6e6; //light borders /tr line
$gray-3: #f3f3f3; // disabled -> menuitems bg,stepper icon bg
$gray-4: #f6f8fa; //th bg
$gray-5: #767676; //kitstatus date,accordion desc
$gray-6: #f8fafb;
$gray-7: #fbfbfb;
$gray-8: #f9fbfc;
$gray-9: #f0f4f7;
$gray-10: #f6fcfc;

$dark-gray-1: #c3c3c3; //disabled-> menuitems icon, stepper icon

$black: #000000; //accordion header //downloadCardheader
$black-1: #4a4a4a; //text
$black-2: #070707; //accordion-arrow
$black-3: #323232;

$pink-1: #f50057; //contactsupport

$red-1: #fff1f1; //cp iconbg
$red-2: #ffeeee; // bd iconbg
$red-2: #f25b5b; //cp icon
$red-3: #d33434; //tnp
$red-4: #f80505; //bd icon

$purple-1: #f4f1ff; //cys iconbg
$purple-2: #704aff; //cys icon

$yellow-1: #fffaed; //syk iconbg
$yellow-2: #f7b500; //syk icon

$green-1: #f5feea; //rk iconbg
$green-2: #7ed321; // kitstatus,stepper-icon-bg text,rk icon
$green-3: #84bc3b;

$teal-1: #edfffb; //rr iconbg
$teal-2: #5addbf; //rr icon

$teal-3: #05eacd;
$teal-4: #2ed3c5;
$teal-5: #0aeba4;
$teal-6: #01eca5;
$teal-7: #0ed9d4;
$teal-8: #19b8ab;
$teal-9: #23d3cf;

$border-light: 1px solid $gray-2;
