@import "globals.scss";
.get_help_body {
  padding: 0rem 1rem 3rem;
  background: $gray-7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .get_help_text {
    padding-bottom: 0rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.7rem;
    text-align: center;
    letter-spacing: -0.05rem;
    color: $black-3;
    padding: 4rem;
  }
  .get_help_card {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 2rem;
    margin: 0rem 3rem;
  }
}
