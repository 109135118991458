@import "globals.scss";
.request_demo {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea,
  select {
    outline: none;
  }

  padding: 12rem 12.5rem 10rem;
  max-width: 1440px;
  &_check {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 2rem;
  }
  &_form {
    width: 533.96px;
    height: 675px;
    margin-top: 1rem;
    left: 146px;
    top: 5440.47px;
    background: $gray-8;
    border-radius: 30px;
    padding: 1rem 3.5rem 2rem;
    &_headline {
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      color: $black;
      margin: 3rem 0;
    }
    &_field {
      margin: 2rem 0;
      .each_field {
        width: 100%;
        background: $gray-9;
        border-radius: 8px;
        height: 55px;
        border: 0;
        color: $black;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        opacity: 0.6;
        padding: 0 2rem;

        &:hover {
          border: 2px solid $blue-9;
        }
        &:focus {
          border: 2px solid $blue-9;
        }
      }
      .error {
        width: 100%;
        background: $gray-9;
        border-radius: 8px;
        height: 55px;
        border: 2px solid $red-4;
        color: $black;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        opacity: 0.6;
        padding: 0 2rem;
      }
    }
    .button {
      width: 100%;
      height: 55px;
      border: 0;
      color: $white;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      margin: 2rem 0;
      background: linear-gradient(93.03deg, $blue-9 -27.2%, $teal-7 135.73%);
      border-radius: 8px;
      &:hover {
        background: linear-gradient($teal-8, $teal-8);
      }
      &:active {
        transform: scale(0.997);
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
          0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
      }
    }
  }
  &_try {
    height: 670px;
    margin-left: 12rem;
    border-radius: 8px;
    margin-top: -57rem;
    position: relative;
    z-index: -20;
    @media only screen and (min-width: 1801px) {
      margin-top: -68.5rem;
    }
    background-color: $blue-8;
    &_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 414px;
      margin: 0 10rem 0 5rem;
      padding-right: 1.5rem;

      &_headline {
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;

        letter-spacing: -0.5px;

        color: $white;
      }
      &_links {
        font-weight: 700;
        font-size: 25px;
        color: $white;
        margin: 4rem 0 5rem;
        line-height: 40px;
        a {
          text-decoration: underline;
        }
        span {
          background-color: $green-3;
        }
      }
      .try {
        width: 9.5rem;
        padding: 10px 10px;
        background-color: $teal-4;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        &:hover {
          background-color: $teal-8;
        }
        &:active {
          box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
            0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        }
        &_link {
          color: $white;
          text-decoration: none;
        }
        @media only screen and (min-width: 1800px) {
          width: 10.7rem;
        }
        @media only screen and (max-width: 1300px) {
          width: 8.3rem;
          font-size: 16px;
        }
        @media only screen and (max-width: 1000px) {
          width: 7.6rem;
          font-size: 14px;
        }
      }
    }
  }
}

@include dynamicQuery(1300px) {
  .request_demo {
    padding: 8rem 6rem 8rem;
    &_form {
      width: 412.6px;
      height: 575px;
      &_headline {
        font-size: 28px;
        line-height: 36px;
      }
      &_field {
        .each_field {
          height: 42.5px;
        }
        .error {
          height: 42.5px;
        }
      }
      .button {
        height: 42.5px;
      }
    }
    &_try {
      height: 580px;
      margin-top: -48.6rem;
      &_box {
        width: 341px;
        margin: 0 5rem 0 5rem;
        padding-right: 1rem;

        &_headline {
          font-size: 28px;
          line-height: 34px;
        }
        &_links {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }
}

@include dynamicQuery(1000px) {
  .request_demo {
    padding: 4rem 2rem 8rem;
    &_form {
      width: 329.99px;
      height: 475px;

      &_headline {
        font-size: 24.7203px;
        line-height: 32px;
      }
      &_field {
        margin: 1.5rem 0;
        .each_field {
          height: 33.99px;
          font-size: 11.1241px;
          line-height: 16px;
        }
        .error {
          height: 33.99px;
          font-size: 11.1241px;
          line-height: 16px;
        }
      }
      .button {
        height: 33.99px;
        font-size: 11.1241px;
        line-height: 16px;
      }
    }
    &_try {
      height: 480px;
      margin-top: -40.3rem;

      &_box {
        width: 255.85px;
        margin: 0 3rem 0 2rem;
        padding-right: 1rem;
        &_headline {
          font-size: 25px;
          line-height: 25px;
        }
        &_links {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
  }
}
