@import "globals.scss";
.request_demo {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea,
  select {
    outline: none;
  }

  &_try {
    padding: 3rem 2rem;
    // border-radius: 8px;
    background-color: $blue-8;
    &_box {
      display: flex;
      flex-direction: column;
      margin: 0 3rem;
      &_headline {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.5px;
        color: $white;
      }
      &_links {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.5px;
        color: $white;
        margin: 2rem 0 5rem;
        a {
          text-decoration: underline;
        }
      }
    }
  }
  &_form {
    background: $gray-8;
    border-radius: 30px;
    padding: 1rem 3.5rem 2rem;
    &_headline {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: $black;
      margin: 3rem 0;
    }
    &_field {
      margin: 1.5rem 0;
      .each_field {
        width: 100%;
        background: $gray-9;
        border-radius: 8px;
        height: 35.36px;
        border: 0;
        color: $black;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        opacity: 0.6;
        padding: 0 2rem;
        &:hover {
          border: 2px solid $blue-9;
        }
        &:focus {
          border: 2px solid $blue-9;
        }
      }
    }
    .button {
      width: 100%;
      height: 35.36px;
      border: 0;
      color: $white;
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 2rem;
      background: linear-gradient(93.03deg, $blue-9 -27.2%, $teal-7 135.73%);
      border-radius: 8px;
      &:hover {
        background: linear-gradient($teal-8, $teal-8);
      }
      &:active {
        transform: scale(0.997);
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
          0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
      }
    }
  }
}
.error {
  width: 100%;
  background: $gray-9;
  border-radius: 8px;
  height: 35.36px;
  border: 0;
  color: $black;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  opacity: 0.6;
  padding: 0 2rem;
  border: 2px solid $red-4;
}
