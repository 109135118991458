.red-text {
  @include red-text;
}
.header_icon_red {
  @include header-button;
  background-color: $red-1;
  color: $red-2;
}
.header_icon_blue {
  @include header-button;
  background-color: $blue-2;
  color: $blue-6;
}
.header_icon_skyblue {
  @include header-button;
  background-color: $blue-3;
  color: $blue-5;
}
.header_icon_green {
  @include header-button;
  background-color: $green-1;
  color: $green-2;
}
.header_icon_brown {
  @include header-button;
  background-color: $brown-1;
  color: $brown-2;
}
.header_icon_purple {
  @include header-button;
  background-color: $purple-1;
  color: $purple-2;
}

.header_icon_yellow {
  @include header-button;
  background-color: $yellow-1;
  color: $yellow-2;
}

.header_icon_olivegreen {
  @include header-button;
  background-color: $teal-1;
  color: $teal-2;
}
