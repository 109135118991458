@import "globals.scss";
.main {
  max-width: 1440px;
  align-items: center;
  background-color: $black;
  .e_commerce {
    grid-area: 2/1/3/2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 6rem 10rem;
    background-color: $black;
    column-gap: 9rem;
    color: $white;
    position: relative;
    z-index: 2;
    &_head {
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      span {
        color: $teal-9;
      }
    }
    &_sub {
      font-family: $font-primary;
      font-size: 18px;
      line-height: 24px;
      padding-right: 10rem;
    }
  }

  @include dynamicQuery(1300px) {
    .e_commerce {
      padding: 6rem 6rem;

      &_head {
        font-size: 30px;
        line-height: 39px;
      }
      &_sub {
        font-size: 15px;
        line-height: 15px;
        padding-right: 0rem;
      }
    }
  }

  @include dynamicQuery(1000px) {
    .e_commerce {
      padding: 6rem 4rem;
      column-gap: 3rem;
      &_head {
        font-size: 24px;
        line-height: 31px;
        padding-right: 3rem;
      }
      &_sub {
        font-size: 13px;
        line-height: 18px;
        padding-right: 0rem;
      }
    }
  }
}
