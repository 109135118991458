@import "globals.scss";
.tryitfreeheader {
  @include complete-center;
  z-index: -2;
  background: linear-gradient(90deg, $white 50%, #e7f5f3 50%);
}
.tryitfreefooter {
  @include complete-center;
  background-color: $black;
  z-index: 20;
  margin-top: -3px;
}
