@import "globals.scss";
.main {
  max-width: 1440px;
  align-items: center;
  .try_it_free {
    font-family: $font-primary;
    margin: 0 3rem 0 10rem;

    display: grid;
    background-color: $gray-10;
    &_mainImage {
      grid-area: 1/1/3/2;
      margin-bottom: -5px;
    }
    &_heading {
      grid-area: 1/1/2/2;
      display: flex;
      flex-direction: row;
      justify-content: space-between; //1
      z-index: 2;
      margin-right: 10rem;
      padding: 3rem 0;

      .sign {
        display: flex;
        flex-direction: row;
        justify-content: space-between; //1
        height: 4rem;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        margin-right: 2rem;
        @media only screen and (min-width: 1800px) {
          height: 5rem;
        }
        &_in {
          padding: 12px 25px 5px 25px;
        }
        &_up {
          // padding: 12px 25px 5px 25px;
          height: 53px;
          width: 127px;
          display: grid;
          place-items: center;

          background-color: $teal-4;
          border-radius: 8px;
          text-align: center;
          &:hover {
            background-color: $teal-8;
          }
          &:active {
            box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
              0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
          }
        }
        a {
          text-decoration: none;
        }
        #sign_up_link {
          color: $white;
        }
        #sign_in_link {
          color: $black;
        }
      }
    }
    &_main {
      z-index: 2;
      grid-area: 2/1/3/2;
      display: grid;
      grid-template-columns: 3fr 3fr;
      .content {
        display: flex;
        flex-direction: column;
        padding-right: 10rem;
        padding-bottom: 5rem;
        &_head {
          font-family: $font-secondary;
          font-weight: bold;
          font-size: 50px;
          line-height: 65px;
          padding-right: 4rem;
          margin: 1rem 0 0rem;
        }
        &_sub {
          font-size: 16px;
          line-height: 19px;
          margin: 3rem 0 3rem;
          // text-transform: capitalize;
          &_one {
            margin-bottom: 1rem;
          }
        }
        .try {
          width: 9.5rem;
          padding: 10px 10px;
          background-color: $teal-4;
          border-radius: 5px;
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          &:hover {
            background-color: $teal-8;
          }
          &:active {
            box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
              0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
          }
          &_link {
            color: $white;
            text-decoration: none;
          }
          @media only screen and (min-width: 1800px) {
            width: 10.7rem;
          }
          @media only screen and (max-width: 1300px) {
            width: 8.3rem;
            font-size: 16px;
          }
          @media only screen and (max-width: 1000px) {
            width: 7.6rem;
            font-size: 14px;
          }
        }
      }
    }
  }

  // 1024 px
  @include dynamicQuery(1300px) {
    .try_it_free {
      margin: 0 3rem 0 6rem;

      &_heading {
        margin-right: 3rem;
        .sign {
          &_up {
            // padding: 12px 25px 5px 25px;
            // height: 53px;
            width: 119px;
          }
        }
      }
      &_main {
        .content {
          padding-right: 4.5rem;
          &_head {
            font-size: 40px;
            line-height: 52px;
          }
          &_sub {
            margin: 2rem 2rem 3rem 0;
          }
        }
      }
    }
  }

  // 744 px
  @include dynamicQuery(1000px) {
    .try_it_free {
      margin: 0 0 0 2rem;

      &_heading {
        margin-right: 0;
        padding: 1rem 0 0;
        .sign {
          &_up {
            // padding: 12px 25px 5px 25px;
            height: 42px;
            width: 91px;
          }
        }
      }
      &_main {
        padding-left: 2rem;
        .content {
          padding-right: 4rem;
          padding-bottom: 0;

          &_head {
            font-size: 30px;
            line-height: 39px;
          }
          &_sub {
            font-size: 14px;
            line-height: 16px;
            margin: 1rem 4.5rem 2rem 0;
          }
        }
      }
    }
  }
}
