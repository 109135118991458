@import "globals.scss";
.create_care_body {
  display: flex;
  flex-direction: column-reverse;
  .create_care_body_image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .create_care_body_description {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    .create_care_body_description_header {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .create_care_body_description_text {
      text-align: center;
      font-size: 14px;
      padding-bottom: 0rem;
    }
    .create_care_body_description_points {
      display: flex;
      align-items: center;
      padding-top: 2rem;
      text-align: center;
      justify-content: center;
      padding-bottom: 0.5rem;

      .create_care_body_description_points_icon {
        padding-right: 8px;
        padding-top: 4px;
      }
      .create_care_body_description_points_text {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
