@import "globals.scss";
.footer {
  &_desktop {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // flex-direction: column;

  background: #000000;
}
