@import "globals.scss";
.get_help_card {
  justify-content: center;
  background: $white;
  border-radius: 3rem;
  padding: 4rem 3rem 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .get_help_card_body {
    text-align: center;
    .get_help_card_image {
      display: grid;
      grid-template-columns: auto 10rem auto;
      .images {
        grid-area: 1/2/2/3;
      }
    }
    .get_help_card_image_mobile {
      display: none;
    }
    .get_help_card_text1 {
      padding-top: 3.5rem;
      padding-bottom: 1rem;
      font-weight: 700;
      font-size: 24px;
      line-height: 3.1rem;
      display: flex;
      justify-content: center;
    }
    .get_help_card_text2 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 2.9rem;
      display: flex;
      justify-content: center;
      color: $black;
      opacity: 0.6;
    }
  }
}
@include dynamicQuery(1300px) {
  .get_help_card {
    padding: 2rem 0rem 0;
    .get_help_card_body {
      .get_help_card_image {
        display: grid;
        grid-template-columns: auto 8rem auto;
      }

      .get_help_card_text1 {
        font-size: 18px;
        line-height: 23px;
        padding-top: 2.5rem;
      }
      .get_help_card_text2 {
        font-size: 13px;
        line-height: 15px;
        padding-bottom: 1rem;
      }
    }
  }
}
@include dynamicQuery(1000px) {
  .get_help_card {
    padding: 2rem 0rem 0;
    .get_help_card_body {
      .get_help_card_image {
        display: grid;
        grid-template-columns: auto 6rem auto;
      }

      .get_help_card_text1 {
        font-size: 14.993px;
        line-height: 20px;
        padding-top: 2.5rem;
      }
      .get_help_card_text2 {
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 0;
      }
    }
  }
}
