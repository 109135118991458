@import "globals.scss";
.create_care_body {
  display: grid;
  grid-template-columns: 3fr 3fr;
  column-gap: 3rem;
  max-width: 1240px;
  margin: 12rem 15rem 20rem;
  &_image {
    display: flex;
    justify-content: right;
    padding-top: 6rem;
    &_one {
      padding-right: 3.8rem;
      display: grid;
      grid-auto-rows: 715px;
      grid-auto-columns: 396px;
    }
    &_two {
      padding-right: 3.8rem;
      display: grid;
    }
    &_three {
      display: grid;
    }
    &_hide {
      display: none;
    }
    @media only screen and (max-width: 1300px) {
      padding-top: 2rem;
      &_one {
        padding-right: 3.4rem;
        display: grid;
        grid-auto-rows: 575px;
        grid-auto-columns: 430px;
      }
      &_two {
        padding-right: 3.8rem;
        display: grid;
      }
    }
    @media only screen and (min-width: 1801px) {
      padding-top: 3rem;
      &_one {
        padding-right: 5rem;
        display: grid;
        grid-template-rows: 640px;
        grid-auto-columns: 460px;
      }
      &_two {
        padding-right: 5rem;
        display: flex;
      }
      &_three {
        display: flex;
      }
    }
  }
  &_description {
    padding-right: 5rem;
    &_main {
      display: grid;
      row-gap: 4rem;
      margin: 2rem 0;
    }
    &_header {
      font-size: 40px;
      font-weight: 700;
      padding-bottom: 40px;
      display: flex;
      text-align: left;
      padding-left: 1rem;
      letter-spacing: -0.5px;
      text-transform: capitalize;
    }
    &_text {
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
      text-align: left;
      padding-left: 1rem;
    }
    &_points {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      padding-bottom: 20px;
      text-align: left;
      justify-content: left;

      &_icon {
        padding-right: 8px;
        padding-top: 4px;
      }
      &_text {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

.one {
  border-left: 3px solid $black;
  .create_care_body_description_text {
    color: $black;
  }
}

@media only screen and (max-width: 1300px) {
  .create_care_body {
    margin: 8rem 6rem 10rem;
    &_description {
      padding-right: 2rem;
      &_main {
        row-gap: 3rem;
      }
      &_header {
        font-size: 30px;
        line-height: 39px;
        padding-bottom: 40px;
      }
      &_text {
        font-size: 14px;
        line-height: 18px;
      }
      &_points {
        padding-bottom: 20px;
        &_text {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .create_care_body {
    margin: 4rem 2rem 5rem;

    &_description {
      padding-right: 0;
      &_main {
        row-gap: 1rem;
      }
      &_header {
        font-size: 26px;
        line-height: 34px;
        padding-bottom: 20px;
      }
      &_text {
        font-size: 13px;
        line-height: 20px;
      }
      &_points {
        padding-bottom: 10px;
        &_text {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .create_care_body {
    &_image {
      padding-top: 2rem;
      &_one {
        padding-right: 3rem;
        display: grid;
        grid-auto-rows: 540px;
        grid-auto-columns: 350px;
      }
      &_two {
        padding-right: 2.6rem;
        display: grid;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .create_care_body {
    &_image {
      padding-top: 2rem;
      &_one {
        padding-right: 2.6rem;
        display: grid;
        grid-auto-rows: 465px;
        grid-auto-columns: 295px;
      }
      &_two {
        padding-right: 2.6rem;
        display: grid;
      }
    }
  }
}
