@import "globals.scss";
.main {
  display: flex;
  flex-direction: column;
  .try_it_free_arch {
    position: absolute;
    left: 0%;
    background: #0ba085;
    opacity: 0.1;
    height: 100%;
    width: 100%;
    border-top-left-radius: 0%;
    border-bottom-left-radius: 150%;
  }
  .try_it_free_heading {
    z-index: 2;
    display: flex;
    padding: 1rem 0rem 0rem 1rem;
    justify-content: space-between;
    align-items: center;
    .logo {
      display: grid;
      grid-template-columns: auto auto;
      &_text {
        display: flex;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 37px;
        align-items: center;
        color: $black;
      }
      @media only screen and (max-width: 330px) {
        grid-template-rows: 30px;
        &_text {
          font-size: 12px;
        }
      }
    }
    .sign {
      display: flex;
      flex-direction: row;
      justify-content: space-between; //1
      height: 4rem;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-right: 2rem;
      padding-top: 0.5rem;
      @media only screen and (max-width: 330px) {
        margin-right: 1rem;
      }

      &_in {
        width: 83px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      &_up {
        width: 83px;
        height: 35px;
        background-color: $teal-9;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        &:hover {
          background-color: $teal-8;
        }
        &:active {
          box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
            0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        }
      }
      a {
        text-decoration: none;
      }
      #sign_up_link {
        color: $white;
      }
      #sign_in_link {
        color: $black;
      }
    }
  }
  .image {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    padding: 4.6rem 0rem 3rem;
  }
  .content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 50px 50px 0px 0px;
    padding: 3rem 2rem 4rem;
    &_head {
      padding: 0rem 3rem 3rem;
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
    }
    &_sub {
      padding: 0rem 2.3rem 3rem;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: $black;

      &_one {
        margin-bottom: 1rem;
      }
    }
    .try {
      padding: 1.3rem 0px 1.3rem 0px;
      width: 100%;
      background-color: $teal-9;
      border-radius: 8px;
      text-align: center;
      &:hover {
        background-color: $teal-8;
      }
      &:active {
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
          0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
      }
      &_link {
        color: $white;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .e_commerce {
    background: #222332;
    padding: 2.8rem;
    &_head {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: $white;
      padding-bottom: 1.5rem;
      span {
        color: $teal-9;
      }
    }
    &_sub {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-transform: capitalize;
      color: $white;
    }
  }
}
