@import "globals.scss";
.footer_mobile {
  max-width: 600px;
  padding: 33px 0;
  margin: 0 108px;
  display: grid;
  row-gap: 30px;
  &_content1 {
    display: flex;
    justify-content: space-between;
    .head {
      color: $teal-4;
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      padding-bottom: 6px;
    }
    .sub {
      color: $white;
      font-size: 12px;
      line-height: 21px;
      padding-top: 6px;
      &_boldtext {
        font-weight: 500;
      }
      a {
        text-decoration: none;
        color: $white;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
  &_content2 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  &_content3 {
    display: flex;
    justify-content: flex-end;
    margin: 6px 0 40px;
  }

  &_logos {
    &_media {
      display: flex;
      .facebook,
      .linkedIn,
      .youtube {
        margin-right: 8px;
      }
    }
  }
}

//at 600 px
@include dynamicQuery(600px) {
  .footer_mobile {
    margin: 0 78px;
  }
}
//at 500 px
@include dynamicQuery(450px) {
  .footer_mobile {
    margin: 0 10px;
  }
}
