@import "globals.scss";
.get_help_body {
  padding: 5rem 9rem 8rem;
  max-width: 1440px;
  background: $gray-7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // @include dynamicQuery(700px) {
  //   padding: 1rem;
  // }
  .get_help_text {
    padding-bottom: 10rem;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 6rem;
    text-align: center;
    letter-spacing: -0.05rem;
    color: $black-3;
    padding: 2rem;
  }
  .get_help_card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 4rem;
    column-gap: 2rem;
  }
}

@include dynamicQuery(1300px) {
  .get_help_body {
    padding: 5rem 2rem 4rem;

    .get_help_text {
      font-size: 30px;
      line-height: 60px;
      padding: 0;
    }
  }
}
@include dynamicQuery(1000px) {
  .get_help_body {
    padding: 5rem 0 4rem;

    .get_help_text {
      font-size: 26px;
      padding: 0;
    }
    .get_help_card {
      margin: 2rem;
    }
  }
}
