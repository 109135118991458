@import "globals.scss";
.create_care_footer {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  background: rgba(251, 251, 251, 0.96);
  padding: 3rem 1rem 1rem;
  .create_care_footer_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .create_care_footer_description_1 {
      background: linear-gradient(93.03deg, #25a4e7 -27.2%, #05eacd 135.73%);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-size: 3.6rem;
      color: #ffffff;
      letter-spacing: -0.5px;
      font-family: $font-secondary;
      border-radius: 50%;
      width: 12rem;
      height: 12rem;
    }
    .create_care_footer_description_2 {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 16px;
      margin: 1.5rem 2rem 2rem;
    }
  }
}
