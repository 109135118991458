@import "globals.scss";
.shop_builder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 8rem 12rem 6rem;
  row-gap: 3rem;
  column-gap: 4rem;
  max-width: 1440px;
  p {
    margin: 0;
  }
  &_head {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    text-transform: capitalize;
    color: #323232;
    padding: 0 4rem 3rem 2rem;
  }
  &_content {
    display: grid;
    row-gap: 2rem;
    .content {
      .box {
        width: 530px;
        display: grid;
        grid-template-columns: 5rem auto;
        margin: 1rem 3rem;
        column-gap: 2rem;
        padding: 1rem 1.5rem;
        cursor: pointer;
      }
      .boxChange {
        width: 530px;
        background: $white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        display: grid;
        grid-template-columns: 5rem auto;
        margin: 1rem 3rem;
        column-gap: 2rem;
        padding: 1rem 1.5rem;
        cursor: pointer;
      }
      &_para {
        .head {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 28.13px;
          // text-transform: capitalize;
          color: $black;
          margin-top: 0.6rem;
        }
        .show {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          // text-transform: capitalize;
          color: #25a4e7;
          margin-top: 0.5rem;
        }
        .sub {
          &_heading {
            font-weight: 500;
            font-size: 21px;
            margin: 10px 0;
          }
          &_points {
            display: grid;
            grid-template-columns: 2rem auto;
            column-gap: 1rem;
            font-family: $font-primary;
            font-weight: normal;
            font-size: 18px;
            margin-bottom: 5px;
            p {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
  &_image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // .gifShow{

  // }
  .gifHide {
    display: none;
  }
}

@include dynamicQuery(1300px) {
  .shop_builder {
    padding: 8rem 6rem 6rem;
    column-gap: 3rem;
    &_head {
      font-size: 30px;
      line-height: 39px;
      padding: 0 4rem 3rem 3rem;
    }
    &_content {
      row-gap: 0rem;
      .content {
        .box {
          width: 416px;
          grid-template-columns: 3rem auto;
        }
        .boxChange {
          width: 416px;
          grid-template-columns: 3rem auto;
        }
        &_para {
          .head {
            font-size: 18px;
            line-height: 21px;
          }
          .show {
            font-size: 18px;
            line-height: 21px;
          }
          .sub {
            &_heading {
              font-size: 16px;
              line-height: 19px;
            }
            &_points {
              grid-template-columns: 1.3rem auto;
              font-size: 14px;
              line-height: 30px;
              p {
                margin-left: 0rem;
              }
            }
          }
        }
      }
    }
  }
}
@include dynamicQuery(1000px) {
  .shop_builder {
    padding: 5rem 2rem 5rem;
    column-gap: 2rem;
    &_head {
      font-size: 26px;
      line-height: 34px;
      padding: 0 4rem 0rem 3rem;
    }
    &_content {
      row-gap: 0rem;
      .content {
        .box {
          width: 360px;
          grid-template-columns: 2rem auto;
          // padding: 0 1.5rem;
        }
        .boxChange {
          width: 360px;
          grid-template-columns: 2rem auto;
        }
        &_para {
          .head {
            font-size: 16px;
            line-height: 19px;
            margin-top: 0.2rem;
          }
          .show {
            font-size: 16px;
            line-height: 19px;
            margin-top: 0.2rem;
          }
          .sub {
            &_heading {
              font-size: 14px;
              line-height: 16px;
            }
            &_points {
              grid-template-columns: 1.3rem auto;
              // column-gap: 1rem;
              font-size: 12px;
              line-height: 20px;
              // p {
              //   margin-left: 0rem;
              // }
            }
          }
        }
      }
    }
  }
}
