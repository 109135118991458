@import "globals.scss";
.create_care_footer {
  display: grid;
  max-width: 1440px;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  padding: 8rem 15rem 6rem;
  &_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_1 {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: 72px;
      line-height: 60px;
      color: $white;
      letter-spacing: -0.5px;
      font-family: $font-secondary;
    }
    &_2 {
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 10px;
      font-size: 16px;
      color: $white;
      margin: 2rem;
    }
    &_3 {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: 72px;
      line-height: 60px;
      letter-spacing: -0.5px;
      color: $white;
      font-family: $font-secondary;
    }
  }
  &_line {
    width: 2px;
    background-color: $gray-1;
    margin-bottom: 4rem;
  }
}

@include dynamicQuery(1300px) {
  .create_care_footer {
    padding: 4rem 5rem 3rem;
    &_description {
      &_1 {
        font-size: 50px;
        line-height: 60px;
      }
      &_2 {
        font-size: 14px;
        line-height: 16px;
      }
      &_3 {
        font-size: 50px;
        line-height: 60px;
      }
    }
  }
}
@include dynamicQuery(1000px) {
  .create_care_footer {
    padding: 4rem 0rem 2rem;
    &_description {
      &_1 {
        font-size: 40px;
        line-height: 60px;
      }
      &_2 {
        font-size: 12px;
        line-height: 14px;
        margin: 0rem 2rem 2rem;
      }
      &_3 {
        font-size: 40px;
        line-height: 60px;
      }
    }
  }
}
