@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone-sm {
    @media only screen and (max-width: 25em) {
      @content;
    } //400px
  }

  @if $breakpoint == phone-md {
    @media only screen and (max-width: 31.25em) {
      @content;
    } //500px
  }

  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }

  @if $breakpoint == phone-lg {
    @media only screen and (max-width: 46.875em) {
      @content;
    } //750px
  }

  @if $breakpoint == phone-xl {
    @media only screen and (max-width: 50em) {
      @content;
    } //800px
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == md-desktop {
    @media only screen and (max-width: 90em) {
      @content;
    } //1440
  }
  @if $breakpoint == big-desktop {
    @media only screen and (max-width: 112.5em) {
      @content;
    } //1800
  }
}

@mixin complete-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin dynamicQuery($breakpointInPx) {
  @media only screen and (max-width: $breakpointInPx) {
    @content;
  }
}

@mixin fadedBg {
  background: rgb(227, 228, 230);
  background: linear-gradient(
    180deg,
    rgba(227, 228, 230, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

@mixin red-text {
  color: $red-3;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
@mixin header-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  @include dynamicQuery(700px) {
    width: 3.6rem;
    height: 3.6rem;
  }
}
