@import "globals.scss";
.for_patients {
  background-color: $gray-7;
  max-width: 1440px;
  padding: 7rem 7rem 16rem;
  &_head {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    color: $black-3;
    display: flex;
    justify-content: center;
    margin: 1rem 0 6rem;
    text-align: center;
    .patients {
      color: $blue-6;
    }
    .providers {
      color: $teal-5;
    }
  }
  &_squares {
    margin: 1rem 5rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left {
      background-color: $teal-6;
      height: 88px;
      width: 88px;
      border-radius: 8px;
    }
    .right {
      background-color: $teal-6;
      height: 88px;
      width: 88px;
      border-radius: 8px;
      position: relative;
    }
  }
  &_main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: -5.4rem 7.5rem;
    column-gap: 5rem;
    .content {
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      background: $white;
      z-index: 10;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      &-image {
        height: 100px;
        align-items: center;
      }
      &_head {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: $black;
        padding: 2rem 0;
      }
      &_sub {
        font-size: 14px;
        line-height: 25px;
        color: $black;
        opacity: 0.6;
        align-items: center;
        letter-spacing: 0.15px;
      }
    }
  }
}

@include dynamicQuery(1300px) {
  .for_patients {
    padding: 5rem 1rem 14rem;
    &_head {
      font-size: 30px;
      line-height: 60px;
    }
    &_main {
      column-gap: 3rem;
      .content {
        padding: 2rem 1.4rem;
        &_head {
          font-size: 15px;
          line-height: 18px;
        }
        &_sub {
          font-size: 13px;
          line-height: 17px;
        }
      }
    }
  }
}
@include dynamicQuery(1000px) {
  .for_patients {
    padding: 3rem 0 12rem;
    &_head {
      font-size: 26px;
      line-height: 60px;
    }
    &_squares {
      margin: 1rem 2rem;
    }
    &_main {
      column-gap: 1rem;
      margin: -5.4rem 4.5rem;
      .content {
        padding: 2rem 0.5rem;
        &_head {
          font-size: 14px;
          line-height: 16px;
        }
        &_sub {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
