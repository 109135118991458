/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'DM Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Regular'), url('DMSans-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'DM Sans Italic';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Italic'), url('DMSans-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'DM Sans Medium';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Medium'), url('DMSans-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'DM Sans Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Medium Italic'), url('DMSans-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'DM Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Bold'), url('DMSans-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'DM Sans Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Bold Italic'), url('DMSans-BoldItalic.woff') format('woff');
    }