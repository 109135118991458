@import "globals.scss";
.shop_builder {
  display: grid;
  margin: 3rem 0 3rem;
  row-gap: 3rem;
  p {
    margin: 0;
  }
  &_head {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21.09px;
    text-align: center;
    color: $black-3;
    margin: 0 6rem;
  }
  &_content {
    .content {
      margin: 1rem 2rem;

      .box {
        display: grid;
        grid-template-columns: 3rem auto;
        margin: 1rem 1.5rem;
        cursor: pointer;
      }
      .boxChange {
        display: grid;
        grid-template-columns: 3rem auto;
        margin: 1rem 1.5rem;
        cursor: pointer;
      }
      &_para {
        .head {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          // text-transform: capitalize;
          color: $black;
          &_mobile {
            margin-top: 2px;
            display: flex;
            justify-content: space-between;
          }
        }
        .show {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-transform: capitalize;
          color: $blue-8;
        }
        .sub {
          &_heading {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-transform: capitalize;
            margin: 10px 0;
          }
          &_points {
            display: flex;
            flex-direction: row;
            font-family: $font-primary;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 5px;
            color: $black;
            p {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
  &_image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
