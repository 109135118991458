@import "globals.scss";
.for_patients {
  background-color: $gray-7;
  padding: 2rem 3rem 4rem;
  &_head {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: $black-3;
    display: flex;
    justify-content: center;
    text-align: center;
    .patients {
      color: $blue-6;
    }
    .providers {
      color: $teal-5;
    }
  }

  &_main {
    display: grid;
    .content {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      &-image {
        height: 100px;
        align-items: center;
      }
      &_head {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $black-3;
        margin: 0.7rem 0;
      }
      &_sub {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $black;
        opacity: 0.6;
        align-items: center;
        letter-spacing: 0.15px;
      }
    }
  }
}
