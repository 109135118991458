@font-face {
  font-family: 'Lucida Grande';
  src: url('LucidaGrande.woff2') format('woff2'),
    url('LucidaGrande.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lucida Grande';
  src: url('LucidaGrande-Bold.woff2') format('woff2'),
    url('LucidaGrande-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
